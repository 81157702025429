import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import HeaderMobile from "../components/header/headerMobile"
import SliderSwiper from "../components/sliderSwiper/sliderSwiper";
import SlideWithComponent from "../components/sliderSwiper/slideWithComponent";
import SwiperWithComponentMobile from "../components/sliderSwiper/swiperWithComponentMobile";
import Slider from "../components/slider/slider-marcacao"
import SliderMobile from "../components/slider/slider-marcacaoMobile"
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"



import styled from "styled-components"


const StandSingleCarTemplate = ({ data, pageContext, location }) => {
  return (
    <>
      <SEO title={pageContext.title} image={pageContext.intro.background.childImageSharp.fluid.srcWebp} />
      <Layout>
        {useBreakpoint().mobile ? (
          <>
            <HeaderMobile data={data.globalJson.header} services={true} />

            <SliderSwiper data={[pageContext.mobile.intro]} mobile oneSlideBorder={true} top />

            <SwiperWithComponentMobile slide={pageContext.mobile.multimediaText} location={location} justify/>

            <SliderMobile data={data.marcacaoJson} service={pageContext.title} />

            <FooterMobile data={data.globalJson.footer} long floatButtons={data.globalJson.floatButtons} />
          </>
        ) : (
          <>
            <Header data={data.globalJson.header} services={true} />

            <SliderSwiper data={[pageContext.intro]} oneSlideBorder={true} top />

            {/**<SliderSwiper slide={pageContext.multimediaText}/>**/}

            <SlideWithComponent slide={pageContext.multimediaText} big location={location} justify/>

            <FormStyled>
              <Slider data={data.marcacaoJson} service={pageContext.title}/>
            </FormStyled>

            <Footer data={data.globalJson.footer} long floatButtons={data.globalJson.floatButtons} />
          </>
        )}

      </Layout>
    </>
  )
}

export default StandSingleCarTemplate

const FormStyled = styled.div`

`

export const Json = graphql`
  query servicosTemplate {
    marcacaoJson {
      background {
        childImageSharp {
          fluid(quality: 100, maxWidth: 3400) {
            src
            srcWebp
          }
        }
      }
      backgroundM {
        childImageSharp {
          fluid(quality: 100, maxWidth: 3400) {
            src
            srcWebp
          }
        }
      }
      title
      titleM
      page1 {
        indicator
        brand
        model
        plate
        year
        mileage
        btnText
        btnLink
        btnClass
      }
      page2 {
        indicator
        date
        time
        time1
        time2
        btnText
        btnLink
        btnClass
      }
      page3 {
        indicator
        name
        address
        phone
        email
        btnText
        btnLink
        btnClass
      }
      page4 {
        btnText
        btnLink
        btnClass
      }
      success {
        subtitle
        text
      }
    }
    globalJson {
      header {
        links {
          link
          linkTxt
        }
        sidemenu {
          links {
            link
            linkTxt
            extraLinks {
              link
              linkTxt
            }
          }
          madeBy
        }
      }
      floatButtons{
        btn
        btnLink
      }
      footer {
        linksLeft {
          link
          linkTxt
        }
        linksRight {
          link
          linkTxt
        }
        image {
          childImageSharp {
            fluid(maxWidth: 198, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

  }
`
